import React, { useEffect, useRef, useState } from "react";
import { StyledMap, StyledMapMap } from "./style";
import Map, { clearDraw, getFeature } from "./MapDraw";
import { StyledGridRow } from "../../commons/Grid";
import { Button } from "@3beehivetech/components";

const OpenLayers = ({
  setFormData,
}) => {
  const map = useRef(null);
  const [mapController, setMapController] = useState(null);
  const [gps, setGps] = useState(null);

  useEffect(() => {
    setFormData(gps)
  }, [gps])

  useEffect(() => {

    const controller = Map({
      target: map.current
    });
    setMapController(controller);
    controller.on("click", function (evt) {
      setGps(getFeature(controller))
    })
  }, []);



  return (
    <StyledGridRow noMargin isFullMobile>
      <StyledMap>
        <Button
          onClick={(e) => {
            e.preventDefault()
            clearDraw(mapController)
          }
          }
          style="top: 10px; right: 10px; position: absolute; z-index: 3; text-transform: uppercase;">
          Cancel
        </Button>

        <StyledMapMap ref={map} />
      </StyledMap>
    </StyledGridRow>
  );
};

export default OpenLayers;
