import React, {
  useContext, useEffect, useState,
} from "react";
import {
  StyledBeekeepersMap,
  StyledBeekeepersWrapper,
} from "./style";
import OpenLayers from "../OpenLayers/OpenLayersDraw";


export default ({ }) => {
  return (
    <>
      <StyledBeekeepersWrapper>
        <StyledBeekeepersMap>
          <OpenLayers markers={[]}/>
        </StyledBeekeepersMap>
      </StyledBeekeepersWrapper>
    </>
  );
};
